<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Добавить поставщика</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="addVendorForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model.trim="vendor.name" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Статус</label>
                <md-select v-model="vendor.is_active" @focus="reset">
                  <md-option
                    v-for="item in activeOptions"
                    :key="item.id"
                    :value="item.id"
                    placeholder="Статус"
                  >
                    {{ item.title }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="addNewVendor">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

const initVendor = () => ({
  name: null,
  is_active: null,
});

export default {
  data() {
    return {
      activeOptions: [
        {
          id: 0,
          title: "Не активен",
        },
        {
          id: 1,
          title: "Активен",
        },
      ],
      vendor: initVendor(),
    };
  },

  methods: {
    ...mapActions("vendors", ["createVendor"]),

    async addNewVendor() {
      const isValid = await this.$refs.addVendorForm.validate();
      if (!isValid) {
        return;
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.createVendor(this.vendor);
      if (success) {
        this.vendor = initVendor();
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
